import styled from 'styled-components'
import { Icon } from '../icon/icon'
import { Button } from '../button/button'
import { Typography } from '@material-ui/core'

export const StyledNavIcon = styled(Icon)<{$isProfile?: boolean}>`
  font-size: ${props => props.theme.typography.pxToRem(16)};
  margin-right: ${props => props.$isProfile ? 0 : props.theme.typography.pxToRem(24)};
  color: ${props => props.$isProfile && props.theme.palette.secondary.dark};
  transition: color 0.2s ease;

  &:hover {
    cursor: pointer;
  }

  ${props => props.theme.breakpoints.up('sm')} {
    margin-right: ${props => props.theme.typography.pxToRem(12)};
    padding: 0;
  }
`

export const StyledBrokenBarIcon = styled.button`
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
`

export const StyledIconNavItem = styled.div<{ $focus?: boolean }>`
  display: grid;
  justify-items: center;
  list-style-type: none;

  ${props => props.theme.breakpoints.up('sm')} {
    display: inline-block;
    font-size: ${props => props.theme.typography.pxToRem(18)};
    border-radius: 4px;

    & > a {
      background-color: #FFB81C;
      border: none;
      border-radius: 4px;
      color: ${props => props.theme.palette.primary.dark};
      
      &:hover {
        background-color: #FFB81C;
        border: none;
        color: ${props => props.theme.palette.primary.dark};
      }
    }
  }

  &:hover {
    color: ${props => props.theme.palette.secondary.dark};
  }
`

export const StyledLoginButton = styled(Button)<{target?: string}>`
  font-size: ${props => props.theme.typography.pxToRem(12)};
  padding: ${props => props.theme.typography.pxToRem(10)} ${props => props.theme.typography.pxToRem(5)};
  color: ${props => props.theme.palette.primary.dark};
  border: none;
  background-color: #FFB81C;

  &:hover {
    background-color: #FFB81C;
    border: none;
    color: ${props => props.theme.palette.primary.dark};
  }


  .MuiButton-startIcon svg {
    position: relative;
    top: -1px;
    font-size: 1.2em;
    color: ${props => props.theme.palette.primary.dark};
  }

  ${props => props.theme.breakpoints.up('sm')} {
    font-size: ${props => props.theme.typography.pxToRem(18)};
    line-height: ${props => props.theme.typography.pxToRem(26)};
    border-radius: 4px;

    .MuiButton-startIcon svg {
      position: relative;
      top: -1px;
      font-size: 1em;
    }
  }

  ${props => props.theme.breakpoints.up('md')} {
    line-height: unset;
  }

  ${props => props.theme.breakpoints.up('lg')} {
    line-height: unset;
    padding: ${props => props.theme.typography.pxToRem(5)} ${props => props.theme.typography.pxToRem(25)} ${props => props.theme.typography.pxToRem(5)} ${props => props.theme.typography.pxToRem(25)};
  }

  &::after {
    width: ${props => props.theme.typography.pxToRem(10)};
    height: ${props => props.theme.typography.pxToRem(10)};
  }
`

export const StyledNavBSB = styled(Typography)`
  font-size: ${props => props.theme.typography.pxToRem(18)};
  font-weight: 500;

  & > span {
    font-weight: 300;
    padding-right: ${props => props.theme.typography.pxToRem(5)};
    color: ${props => props.theme.palette.secondary.dark};
  }
`

export const StyledContactUs = styled.a`
  display: none;
  text-decoration: none;
  transition: color 0.2s ease;
  color: ${props => props.theme.palette.primary.dark};
  font-weight: 500;

  &:hover {
    color: ${props => props.theme.palette.secondary.dark} !important;
  }

  ${props => props.theme.breakpoints.up('sm')} {
    display: inline-block;
    background: none !important;
    font-size: ${props => props.theme.typography.pxToRem(18)};
  }
`